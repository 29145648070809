/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
/* global fetch */

import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import InfiniteStories from "./infinite-stories";
import { getInfiniteScrollApi, getInfiniteScrollConfigStyle, maySetGlobals } from "../../utils";
import "../../../assets/stylesheets/grid.scss";
import "./story.m.css";
import { findStoryTemplate, getStoryComponent } from "../../component-bundles/bundling-utils";
import { addSlotsToStory } from "../../../utils/story-ads-from-pb";
import { getCustomSeoData } from "../../../utils/getCustomSeoData";

import camelCase from "lodash/camelCase";
export function StoryPageContent(data, config, renderStoryPageContent, preview) {
  const { story, index } = data || {};
  const firstStory = index === 0;
  const infiniteScrollStyle = getInfiniteScrollConfigStyle(config);
  const showStoryTemplate = infiniteScrollStyle !== "read-more" || (infiniteScrollStyle === "read-more" && firstStory);
  const props = {
    story: story,
    config: config,
    preview: preview,
    firstStory: firstStory,
    index: index,
    showSnapshotTemplate: !showStoryTemplate && !preview,
  };
  return (typeof renderStoryPageContent === "function" && renderStoryPageContent(props)) || null;
}

const getItemsWithComponent = (items, config) => {
  const updatedItems = items.map(async (item) => {
    const storyObj = item.story || item;
    const { storyTemplate } = findStoryTemplate({ story: storyObj, config });
    const storyType = storyTemplate || "text";
    const storyField = ["text-story", "photo-story", "video-story", "liveBlog-story", "listicle-story"].includes(
      `${camelCase(storyType)}-story`
    )
      ? "story"
      : "customStory";
    // adds slots to story elements directly
    const { storyCards, customSlotAfterStory = {} } = addSlotsToStory(
      storyObj.cards,
      get(config, ["pagebuilder-config", storyField, `${camelCase(storyType)}-story`, "story-slots"], {}),
      storyObj.customSlotAfterStory
    );
    storyObj.cards = storyCards;
    storyObj.customSlotAfterStory = customSlotAfterStory;

    const StoryComponent = await getStoryComponent(storyTemplate);

    return {
      story: storyObj,
      StoryComponent,
      customSeo: getCustomSeoData({}, "story-page", storyObj),
    };
  });
  return updatedItems;
};

function storyPageLoadItems(pageNumber, story = {}, config = {}) {
  const infiniteScrollType = get(config, ["pagebuilder-config", "infiniteScroll", "infiniteScrollType"], "");
  const collectionSlug =
    get(config, ["pagebuilder-config", "infiniteScroll", "collectionSlug"]) || "curated-story-list";
  const sectionId = get(story, ["sections", 0, "id"], null);
  const params = {
    pageNumber,
    sectionId,
    collectionSlug,
  };
  return getInfiniteScrollApi(infiniteScrollType, params, config, story)
    .then((url) => fetch(url))
    .then((response) => response.json())
    .then(async (response) => {
      if (response?.data?.items.length) {
        const items = get(response, ["data", "items"]).filter((item) => item.type === "story" && item.id !== story.id);
        return await Promise.all(getItemsWithComponent(items, config));
      }
      const items = response?.stories || response?.items || [];
      return await Promise.all(getItemsWithComponent(items, config));
    })
    .catch((err) => {
      console.error("Error in retrieving infinite scroll stories :", err);
      return [];
    });
}

export class StoryPage extends React.Component {
  doAnalytics(story) {
    const { registerPageView } = require("@quintype/framework/client/analytics");
    registerPageView({ type: "story-page", story }, `/${story.slug}`);
  }

  onStoryFocus(item) {
    const { story } = item;
    console.log(`Story In View: ${item.story.headline}`);
    const getPageData = (storyKey = "", storyValue = "") => get(story, [storyKey], []).map((item) => item[storyValue]);
    const pageData = {
      "page-type": "story-page",
      "story-slug": get(story, ["slug"], null),
      "story-id": get(story, ["id"], null),
      "story-type": get(story, ["story-template"], null),
      "primary-section": get(getPageData("sections", "slug"), [0]) || null,
      sections: getPageData("sections", "slug").join(",") || null,
      authors: getPageData("authors", "name").join(",") || null,
      "sections-name": getPageData("sections", "name").join(",") || null,
      "sections-display-name": getPageData("sections", "display-name").join(",") || null,
      "authors-id": getPageData("authors", "id").join(",") || null,
    };
    maySetGlobals(pageData);
  }

  render() {
    const infiniteScrollType = get(
      this.props.config,
      ["pagebuilder-config", "infiniteScroll", "infiniteScrollType"],
      ""
    );
    const firstStory = this.index === 0;
    const infiniteScrollStyle = getInfiniteScrollConfigStyle(this.props.config);
    const showStoryTemplate =
      infiniteScrollStyle !== "read-more" || (infiniteScrollStyle === "read-more" && firstStory);
    if (this.props.preview || infiniteScrollType === "disabled") {
      return StoryPageContent(
        this.props.data,
        this.props.config,
        this.props.renderStoryPageContent,
        this.props.preview
      );
    }
    return (
      <div className="infinite-wrapper">
        {!showStoryTemplate && !this.props.preview ? (
          <InfiniteStories
            {...this.props}
            renderStoryPageContent={this.props.renderStoryPageContent}
            loadItems={storyPageLoadItems}
            onItemFocus={this.onStoryFocus}
            onInitialItemFocus={(item) => this.doAnalytics(item.story)}
            doNotChangeUrl={true}
            neverHideItem={true}
          />
        ) : (
          <InfiniteStories
            {...this.props}
            renderStoryPageContent={this.props.renderStoryPageContent}
            loadItems={storyPageLoadItems}
            onItemFocus={this.onStoryFocus}
            onInitialItemFocus={(item) => this.doAnalytics(item.story)}
            neverHideItem={true}
          />
        )}
      </div>
    );
  }
}

StoryPageContent.propTypes = {
  config: PropTypes.object,
  data: PropTypes.shape({
    story: PropTypes.object,
    index: PropTypes.number,
    preview: PropTypes.bool,
  }),
};

StoryPage.propTypes = {
  preview: PropTypes.bool,
  config: PropTypes.object,
  renderStoryPageContent: PropTypes.any,
  data: PropTypes.shape({
    story: PropTypes.object,
    index: PropTypes.number,
    preview: PropTypes.bool,
  }),
};
